const textStyles = {
  baseStyle: {
    letterSpacing: "tight",
    fontSize: "15px",
    color: "grey.700",
    lineHeight: 1.3,
  },
  sizes: {},
  variants: {
    base: {},
    logo: {
      lineHeight: { base: "1", sm: "1", md: "1.1" },
      fontFamily: "Cardo",
      fontWeight: "700",
      color: "white",
      letterSpacing: "0%",
    },
    navlink: {
      color: "white",
      fontSize: "inherit",
      fontFamily: "Montserrat",
      fontWeight: "500",
      px: "2",
      py: "0",
      letterSpacing: "1px",
    },
    aboutText: {
      color: "grey.700",
      fontWeight: "400",
      py: "20px",
    },
    faqText: {
      color: "grey.700",
      fontWeight: "400",
    },
  },
  defaultProps: {
    variant: "base",
  },
};
export { textStyles };
