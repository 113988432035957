// this theme uses gatsby shadowing
// when development bundle is built, @chakra-ui default theme is taken from this file instead of node modules
// src/@chakra-ui/gatsby-plugin/theme.js

import { extendTheme } from "@chakra-ui/react";
import { buttonStyles as Button } from "./components/buttonStyles";
import { textStyles as Text } from "./components/textStyles";

const theme = {
  breakpoints: {
    xs: "350px",
    sm: "475px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  },

  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    red: "#FF3A20",
    blue: "#2229d7",
    orange: "#f5924d",
    purple: "#692f91",
    green: {
      50: "#e2fafa",
      100: "#c6e8e7",
      200: "#a6d6d3",
      300: "#85c6bf",
      400: "#65b6b2",
      500: "#4c999c",
      600: "#39747a",
      700: "#274f57",
      800: "#112c35",
      900: "#000e14",
    },
    grey: {
      50: "#f2f2f2",
      100: "#d9d9d9",
      200: "#bfbfbf",
      300: "#a6a6a6",
      400: "#8c8c8c",
      500: "#737373",
      600: "#595959",
      700: "#404040",
      800: "#262626",
      900: "#0d0d0d",
    },
  },
  fonts: {
    //waiting to agree on fonts
    body: "Work Sans",
    heading: "Montserrat",
    logo: "Work Sans",
  },
  components: {
    Button,
    Text,
  },
};

export default extendTheme(theme);
