const buttonStyles = {
  baseStyle: {
    rounded: "0",
  },
  sizes: {},
  variants: {
    base: {},
  },
  defaultProps: {
    variant: "base",
  },
};
export { buttonStyles };
